/* eslint-disable max-len */
import { RouteConfig } from 'vue-router';

export const consoleRoutes: RouteConfig = {
  path: '/',
  component: () =>
    import(/* webpackPreload: true, webpackChunkName: "console-pages" */ '@/views/layout/console/Layout.vue'),
  children: [
    {
      path: '/',
      name: 'Dashboard',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/dashboard/Dashboard.vue'),
    },

    {
      path: '/notifications',
      name: 'NotificationList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/dashboard/notifications/NotificationList.vue'),
    },



    // Profile
    {
      path: '/myProfile',
      name: 'MyProfile',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/myProfile/Profile.vue'),
    },

    // topbar menu
    {
      path: '/sales/my-invoices',
      name: 'SalesMyInvoices',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/sales/invoices/MyInvoices.vue'),
      meta: { permission: 'Employee.Dashboard.Operation.MyInvoices' },
    },
    {
      path: '/my-physical-accounts',
      name: 'MyPhysicalAccounts',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/physicalAccounts/MyPhysicalAccounts/MyPhysicalAccountsList.vue'
        ),
      meta: { permission: 'Employee.Dashboard.Operation.MyPhysicalAccounts' },
    },
    {
      path: '/current-pricings',
      name: 'CurrentPricings',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/pricing/quickPricingRates/CurrentPricingPreview.vue'),
      meta: {
        permission: 'Employee.Dashboard.Operation.UpToDateExchangeRate',
      },
    },

    {
      path: '/my-business-parties',
      name: 'MyBusinessParties',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/businessParty/businessParties/Confirmed/MyBusinessParties.vue'
        ),
      meta: {
        // permission: 'Employee.Dashboard.Operation.MyPhysicalAccounts'
      },
    },

    // business party routes
    {
      path: '/users',
      name: 'UsersUserList',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/user/userManagement/UserList.vue'),
      meta: { permission: 'Employee.Common.Users' },
    },
    {
      path: '/businessParties',
      name: 'BusinessPartyList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/businessParty/businessParties/Confirmed/BusinessPartyList.vue'
        ),
      meta: { permission: 'Employee.BusinessParties.Customers' },
    },
    {
      path: '/businessParties/pendingState',
      name: 'pendingBusinessPartyList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/businessParty/businessParties/Pending/BusinessPartyList.vue'
        ),
      meta: { permission: 'Employee.Common.UserManagement' },
    },
    {
      path: '/businessParties/rejected',
      name: 'rejectedBusinessPartyList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/businessParty/businessParties/Rejected/BusinessPartyList.vue'
        ),
      meta: { permission: 'Employee.Common.UserManagement' },
    },
    {
      path: '/businessParties/profile/:userId',
      name: 'BusinessPartyProfile',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/profile/Profile.vue'),
      props: true,
      meta: { permissions: ['Employee.BusinessParties.Profiles'] },
    },
    {
      path: '/my-profile',
      name: 'CurrentUserProfile',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/profile/Profile.vue'),
      props: true,
      meta: { permissions: ['Employee.Dashboard.Operation.ShowMyProfile'] },
    },
    {
      path: '/contractor/profile/:userId',
      name: 'ContractorProfile',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/contractor/profile/Profile.vue'),
      props: true,
      // meta: { permissions: ['Employee.BusinessParties.Contractors.DetailContractor'] },
    },
    {
      path: '/businessParties/pending',
      name: 'BusinessPartyPendingList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/_components/PendingList.vue'),
      meta: {
        permissions: ['Employee.BusinessParties'],
      },
    },
    {
      path: '/businessParties/deletedCustomers',
      name: 'deletedCustomersList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/_components/DeletedCustomerList.vue'),
      meta: { permission: 'Employee.BusinessParties.Customers.DeletedCustomersAndAgents' },
    },
    {
      path: '/businessParties/ignored',
      name: 'BusinessPartyIgnoredList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/_components/IgnoredList.vue'),
      meta: {
        permissions: ['Employee.BusinessParties'],
      },
    },
    {
      path: '/businessParties/bankingEmails',
      name: 'BusinessPartyBankingEmailList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/_components/BankingEmailList.vue'),
      meta: {
        permissions: ['Employee.BusinessParties'],
      },
    },
    {
      path: '/customers',
      name: 'BusinessPartiesCustomerList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/customer/CustomerList.vue'),
      meta: { permission: 'Employee.BusinessParties.CustomersList' },
    },
    {
      path: '/agents',
      name: 'BusinessPartiesAgentList',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/agent/AgentList.vue'),
      meta: { permission: 'Employee.BusinessParties.AgentsList' },
    },
    {
      path: '/contractors',
      name: 'BusinessPartiesContractorList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/contractor/ContractorList.vue'),
      meta: { permission: 'Employee.BusinessParties.Contractors' },
    },
    {
      path: '/customer-physical-accounts',
      name: 'BusinessPartiesCustomerPhysicalAccounts',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/physicalAccounts/CustomerPhysicalAccountsList.vue'),
      meta: { permission: 'Employee.BusinessParties.PhysicalAccounts' },
    },
    {
      path: '/contacts',
      name: 'BusinessPartiesContactList',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/businessParty/contacts/ContactList.vue'),
      meta: { permission: 'Employee.BusinessParties.Contacts' },
    },

    // financial routes
    {
      path: '/sales/invoices',
      name: 'SalesInvoices',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/sales/invoices/InvoicesList.vue'),
      meta: { permission: 'Employee.Sales.Invoices' },
    },
    {
      path: '/pricing/pricingRates',
      name: 'PricingPricingRates',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/pricing/pricingRates/PricingRatesList.vue'),
      meta: { permission: 'Employee.Sales.PricingRates' },
    },
    {
      path: '/pricing/quickPricingRates',
      name: 'PricingQuickPricingRates',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/pricing/quickPricingRates/QuickPricingRatesList.vue'),
      meta: { permission: 'Employee.Sales.QuickPricing' },
    },
    {
      path: '/pricing/pricingRateDetails/:id',
      name: 'SalesPricingRatesDetails',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/pricing/pricingRates/PricingRateDetails.vue'),
      meta: { permission: 'Employee.Sales.PricingRates' },
    },
    {
      path: '/sales/wages',
      name: 'SalesWages',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/sales/wages/WagesList.vue'),
      meta: { permission: 'Employee.Sales.Wages' },
    },
    {
      path: '/pricing/calculations',
      name: 'Calculations',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/pricing/calculations/index.vue'),
      meta: { permission: 'Employee.Sales.PricingRateCalculation' },
    },

    // Receipts
    {
      path: '/sales/receptions',
      name: 'ReceiptsReceptions',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/sales/receptions/ReceptionsList.vue'),
      meta: { permission: 'Employee.Receipts.Receptions' },
    },
    {
      path: '/accounting/bank-deposits',
      name: 'ReceiptsBankDeposits',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/bankDeposit/BankDepositList.vue'),
      meta: { permission: 'Employee.Receipts.BankDeposits' },
    },
    {
      path: '/sales/deferred-receptions',
      name: 'ReceiptsDeferredReceptionList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/sales/deferredReceptions/DeferredReceptionList.vue'),
      meta: { permission: 'Employee.Receipts.DeferredReceptions' },
    },
    {
      path: '/sales/deposit-to-pos',
      name: 'DepositToPosList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/sales/DepositToPos/DepositToPosList.vue'),
      meta: { permission: 'Employee.Receipts.PosReception' },
    },

    // Payments
    {
      path: '/sales/payment-requests',
      name: 'PaymentsPaymentRequests',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/sales/paymentRequests/PaymentRequestsList.vue'),
      meta: { permission: 'Employee.Payments.PaymentRequests' },
    },
    {
      path: '/sales/payment-orders-inperson',
      name: 'PaymentsPaymentOrderInPerson',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/inPerson/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.InPersonPayments' },
    },
    {
      path: '/sales/payment-orders-branch-tt',
      name: 'PaymentsPaymentOrderBranchTt',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/branch-tt/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.TTPayments' },
    },
    {
      path: '/sales/payment-orders-branch-local',
      name: 'PaymentsPaymentOrderBranchLocal',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/branch_Local/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.LocalPayments' },
    },
    {
      path: '/sales/payment-orders-branch-email',
      name: 'PaymentsPaymentOrderBranchEmail',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/branch_bankingemail/EmailPaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.BankingEmailPayment' },
    },
    {
      path: '/sales/new-local-payment-orders',
      name: 'PaymentsNewLocalPaymentOrders',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/newPayments/Local_New/localPaymentOrders/LocalPaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.NewLocalPayments.PaymentOrders' },
    },
    {
      path: '/sales/new-local-payments-online',
      name: 'PaymentsNewLocalPaymentsOnline',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/newPayments/Local_New/localChildPaymentOrders/online/LocalOnlinePaymentsList.vue'
        ),
      meta: { permission: 'Employee.Payments.NewLocalPayments.OnlinePayments' },
    },
    {
      path: '/sales/new-local-payments-bank',
      name: 'PaymentsNewLocalPaymentsBank',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/newPayments/Local_New/localChildPaymentOrders/bank/LocalBankPaymentsList.vue'
        ),
      meta: { permission: 'Employee.Payments.NewLocalPayments.BranchPayments' },
    },
    {
      path: '/sales/new-local-payments-fund',
      name: 'PaymentsNewLocalPaymentsFund',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/newPayments/Local_New/localChildPaymentOrders/fund/LocalFundPaymentsList.vue'
        ),
      meta: { permission: 'Employee.Payments.NewLocalPayments.FundPayments' },
    },
    {
      path: '/sales/payment-orders-agent',
      name: 'PaymentsPaymentOrderAgent',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/agent/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.AgentPayments' },
    },
    {
      path: '/sales/payment-orders-customer',
      name: 'PaymentsPaymentOrderCustomer',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/customer/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.CustomerPayments' },
    },
    {
      path: '/sales/incoming-payment-orders-branch',
      name: 'PaymentsIncomingPaymentOrderBranch',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/branch_Incoming/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.IncomingBranchPayments' },
    },
    {
      path: '/sales/outgoing-payment-orders-branch',
      name: 'PaymentsOutgoingPaymentOrderBranch',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/branch_Outgoing/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.OutgoingBranchPayments' },
    },
    {
      path: '/sales/payment-orders-cryptocurrency_branch',
      name: 'Sales_PaymentOrder_Cryptocurrency_Branch',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/paymentOrders/referralTypes/cryptocurrency_Branch/PaymentOrdersList.vue'
        ),
      meta: { permission: 'Employee.Payments.CryptocurrencyPayments' },
    },

    // Exchanges
    {
      path: '/sales/physical-account-exchanges',
      name: 'ExchangesPhysicalAccountExchangeList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/physicalAccountExchanges/PhysicalAccountExchangeList.vue'
        ),
      meta: { permission: 'Employee.Exchanges.PhysicalAccountExchanges' },
    },
    {
      path: '/sales/my-physical-account-exchanges',
      name: 'MyExchangesPhysicalAccountExchangeList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/physicalAccountExchanges/MyPhysicalAccountExchangeList.vue'
        ),
      meta: { permission: 'Employee.Dashboard.Operation.MyExchanges' },
    },
    {
      path: '/sales/my-receptions',
      name: 'MyReceptionList',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/sales/receptions/MyReceptions.vue'),
      meta: { permission: 'Employee.Dashboard.Operation.MyReceptions' },
    },
    {
      path: '/sales/customer-exchanges',
      name: 'ExchangesCustomerExchangeList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/sales/customerExchanges/CustomerExchangeList.vue'),
      meta: { permission: 'Employee.Exchanges.CustomerExchanges' },
    },

    //TradingUnitChanges
    // {
    //   path: '/sales/customer-tradingUnitChanges',
    //   name: 'TradingUnitChangesCustomerTradingUnitChangeList',
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "console-pages" */ '@/views/sales/customerTradingUnitChanges/CustomerTradingUnitChangeList.vue'
    //     ),
    //   meta: { permission: 'Employee.TradingUnitChanges.CustomerTradingUnitChanges' },
    // },

    {
      path: '/sales/physical-account-tradingUnitChanges',
      name: 'TradingUnitChangesPhysicalAccountTradingUnitChangeList',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/sales/physicalAccountTradingUnitChanges/PhysicalAccountTradingUnitChangeList.vue'
        ),
      meta: { permission: 'Employee.TradingUnitChanges.PhysicalAccountTradingUnitChanges' },
    },

    // Accounting
    {
      path: '/sales/physical-accounts',
      name: 'AccountingPhysicalAccounts',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/physicalAccounts/OrganizationPhysicalAccountsList.vue'),
      meta: { permission: 'Employee.Accounting.PhysicalAccounts' },
    },
    {
      path: '/accounting/account-groups',
      name: 'AccountingGroups',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/accountGroups/AccountGroupsList.vue'),
      meta: { permission: 'Employee.Accounting.AccountGroups' },
    },
    {
      path: '/accounting/accounting-accounts',
      name: 'AccountingAccounts',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/accounts/Accounts.vue'),
      meta: { permission: 'Employee.Accounting.Accounts' },
    },
    {
      path: '/accounting/vouchers',
      name: 'AccountingVouchers',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/vouchers/VouchersList.vue'),
      meta: { permission: 'Employee.Accounting.Vouchers' },
    },
    {
      path: '/accounting/vouchers-monitoring',
      name: 'AccountingVouchersMonitoring',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/vouchers/VouchersMonitoringList.vue'),
      meta: { permission: 'Employee.Accounting.AccountingVoucherControl' },
    },
    {
      path: '/accounting/account-book',
      name: 'AccountingAccountBook',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/vouchers/VoucherItemsAccountBook.vue'),
      meta: { permission: 'Employee.Accounting.AccountBooks' },
    },
    {
      path: '/accounting/day-book',
      name: 'AccountingDayBook',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/vouchers/VoucherItemsDayBook.vue'),
      meta: { permission: 'Employee.Accounting.DayBooks' },
    },
    {
      path: '/accounting/fiscal-period',
      name: 'AccountingFiscalPeriod',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/fiscalPeriod/FiscalList.vue'),
      meta: { permission: 'Employee.Accounting.FiscalYears' },
    },
    {
      path: '/accounting/debtors-creditors-commitments',
      name: 'AccountingDebtorsCreditorsWithCommitment',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/accounting/debtors-creditors-commitments/WalletsList.vue'
        ),
      meta: { permission: 'Employee.Accounting.DebtorsCreditorsWithCommitment' },
    },
    {
      path: '/accounting/debtors-creditors',
      name: 'AccountingDebtorsCreditors',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/debtors-creditors/WalletsList.vue'),
      meta: { permission: 'Employee.Accounting.DebtorsCreditors' },
    },
    {
      path: '/accounting/cost-asset-record',
      name: 'AccountingBranchCosts',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/costAssetsRecord/CostAssetsList.vue'),
      meta: { permission: 'Employee.Accounting.BranchCosts' },
    },
    {
      path: '/accounting/balance-sheet',
      name: 'AccountingBalanceSheets',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/balanceSheet/BalanceSheetList.vue'),
      meta: { permission: 'Employee.Accounting.BalanceSheets' },
    },
    {
      path: '/accounting/petty-cashes',
      name: 'AccountingPettyCashList',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/pettyCash/PettyCashList.vue'),
      meta: { permission: 'Employee.Accounting.PettyCashs' },
    },
    {
      path: '/accounting/petty-cash-costs',
      name: 'AccountingPettyCashCostList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/pettyCashCost/PettyCashCostList.vue'),
      meta: { permission: 'Employee.Accounting.PettyCashCosts' },
    },
    {
      path: '/accounting/cost-types',
      name: 'AccountingCostTypes',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/costTypes/CostTypesList.vue'),
      meta: { permission: 'Employee.Accounting.CostTypes' },
    },
    {
      path: '/accounting/cheques',
      name: 'AccountingCheques',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/cheque/ChequesList.vue'),
      meta: { permission: 'Employee.Accounting.Cheques' },
    },
    {
      path: '/accounting/account-wages',
      name: 'AccountingAccountWages',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/accounting/accountWages/AccountWagesList.vue'),
      meta: { permission: 'Employee.Accounting.PhysicalAccountProfitAndCost' },
    },

    // {
    //   path: '/accounting/vouchers',
    //   name: 'AccountingVouchers',
    //   component: () => import(/* webpackChunkName: "console-pages" */ '@/views/accounting/vouchers/VouchersList.vue'),
    //   meta: { permission: 'Employee.Accounting.Vouchers' },
    // },
    // Reports
    {
      path: '/reports/sales/invoices',
      name: 'ReportsSalesInvoicesReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/InvoicesReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.Invoices' },
    },
    {
      path: '/reports/sales/receptions',
      name: 'ReportsSalesReceptionsReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/ReceptionsReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.Receipts' },
    },
    {
      path: '/reports/sales/payment-orders',
      name: 'ReportsSalesPaymentOrdersReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/PaymentOrdersReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.PaymentOrders' },
    },
    {
      path: '/reports/sales/payment-requests',
      name: 'ReportsSalesPaymentRequestsReport',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/PaymentRequestsReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.PaymentRequest' },
    },
    {
      path: '/reports/sales/payments',
      name: 'ReportsSalesPaymentsReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/PaymentsReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.Payments' },
    },
    {
      path: '/reports/sales/discounts',
      name: 'ReportsSalesDiscountsReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/DiscountsReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.Discounts' },
    },
    {
      path: '/reports/sales/wages',
      name: 'ReportsSalesWagesReport',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/wageReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.Wages' },
    },

    {
      path: '/reports/accounting/cheques',
      name: 'ReportsAccountingCheques',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/accounting/ChequesReport.vue'),
      meta: { permission: 'Employee.Reports.Accounting.Cheques' },
    },
    {
      path: '/reports/sales/bank-deposits',
      name: 'ReportsSalesBankDeposits',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/reports/sales/BankDepositsReport.vue'),
      meta: { permission: 'Employee.Reports.Sale.BankDeposits' },
    },
    {
      path: '/reports/accounting/duplicate-vouchers',
      name: 'ReportsAccountingDuplicateVouchers',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/reports/accounting/DuplicateVouchers/DuplicateVouchersReport.vue'
        ),
      meta: {
        permission: 'Employee.Reports.Accounting.DuplicateVoucher',
      },
    },

    // settings
    {
      path: '/settings/employees/create',
      name: 'SettingsCreateEmployee',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/organization-chart/CreateEmployee.vue'),
      meta: { permission: 'Employee.Common.Employees.Create' },
    },
    {
      path: '/settings/employees/edit/:userId',
      name: 'SettingsEditEmployee',
      props: true,
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/organization-chart/EditEmployee.vue'),
      meta: { permission: 'Employee.Common.Employees' },
    },
    {
      path: '/reports/performance/branch-reports',
      name: 'SettingsBranchReports',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/reports/performance/branch-reports/BranchCustomer.vue'),
      meta: { permission: 'Employee.Reports.Performance.BranchReports' },
    },
    {
      path: '/settings/employees/:state?',
      name: 'SettingsOrganizationChart',
      props: true,
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/organization-chart/EmployeeList.vue'),
      meta: {
        permissions: [
          'Employee.Common.Employees',
          'Employee.Common.Employees.PendingEmployees',
          'Employee.Common.Employees.IgnoredEmployees',
          'Employee.Common.Employees.ReadDeletedEmployees',
        ],
      },
    },
    {
      path: '/settings/assets',
      name: 'Assets',
      props: true,
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/assetsList/AssetsList.vue'),
      meta: {
        permissions: ['Employee.Common.Assets'],
      },
    },
    {
      path: '/settings/cost-centers',
      name: 'SettingsCostCenters',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/cost-centers/CostCenterList.vue'),
      meta: { permission: 'Employee.Common.CostCenters' },
    },
    {
      path: '/settings/cost-owners',
      name: 'SettingsCostOwners',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/cost-owners/CostOwnersList.vue'),
      //meta: { permission: 'Employee.Common.CostCenters' },
    },

    {
      path: '/settings/cost-headings',
      name: 'SettingsCostHeadings',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/cost-headings/CostHeadingList.vue'),
      meta: { permission: 'Employee.Common.BranchCostHeading' },
    },

    {
      path: '/settings/branches',
      name: 'SettingsBranches',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/branches/BranchesList.vue'),
      meta: { permission: 'Employee.Common.Branches' },
    },
    {
      path: '/settings/notifications-receivers',
      name: 'SettingsNotificationReceiverList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/notifications/receivers/ReceiverList.vue'),
      meta: { permission: null },
    },
    {
      path: '/settings/notification-templates',
      name: 'SettingsNotificationTemplatesList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/notifications/templates/TemplateList.vue'),
      meta: { permission: null },
    },
    {
      path: '/settings/notifications',
      name: 'SettingsNotificationList',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/notifications/messages/notificationList.vue'),
      meta: { permission: null },
    },
    {
      path: '/reports/performance/branch-customer-status',
      name: 'SettingsCustomersTransactionsStatusReport',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/reports/performance/branch-customer-status/BranchCustomerStatusList.vue'
        ),
      meta: { permission: 'Employee.Reports.Performance.CustomersTransactionsStatusReport' },
    },
    {
      path: '/reports/performance/branch-employee-status',
      name: 'SettingsEmployeeTransactionsStatusReport',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/reports/performance/branch-employee-status/BranchEmployee.vue'
        ),
      meta: { permission: 'Employee.Reports.Performance.EmployeeReports' },
    },
    {
      path: '/reports/performance/most-debtors-and-creditors-report',
      name: 'SettingsMostRecentDebtorsAndCreditorsReport',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/reports/performance/most-debtors-and-creditors-report/debtorsAndCreditorsReport.vue'
        ),
      meta: { permission: 'Employee.Reports.Performance.MostRecentDebtorsCreditors' },
    },
    {
      path: '/settings/banks',
      name: 'SettingsBanks',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/banks/BanksList.vue'),
      meta: { permission: 'Employee.Common.Banks' },
    },
    {
      path: '/settings/tradingUnits',
      name: 'SettingsTradingUnits',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/tradingUnits/TradingUnitsList.vue'),
      meta: { permission: 'Employee.Common.TradingUnits' },
    },
    {
      path: '/settings/countries',
      name: 'SettingsCountries',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/countries/CountriesList.vue'),
      meta: { permission: 'Employee.Common.Countries' },
    },
    {
      path: '/settings/cities',
      name: 'SettingsCities',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/cities/CitiesList.vue'),
      meta: { permission: 'Employee.Common.Cities' },
    },
    {
      path: '/settings/reasons',
      name: 'SettingsReasons',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/reasons/ReasonsList.vue'),
      meta: { permission: 'Employee.Common.Reasons' },
    },
    {
      path: '/settings/roles',
      name: 'SettingsEmployeeRoles',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/roles/EmployeeRoles.vue'),
      meta: { permission: 'Employee.Common.OrganizationRoles' },
    },
    {
      path: '/settings/business-party-groups',
      name: 'SettingsBusinessPartyGroups',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/settings/business-party-groupes/BusinessPartyGroupList.vue'
        ),
      meta: { permission: 'Employee.Common.BusinessPartyRoles' },
    },
    {
      path: '/settings/organizationTeams',
      name: 'SettingsOrganizationTeams',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/organizationTeams/OraganizationTeamList.vue'),
      meta: { permission: 'Employee.Common.BusinessPartyRoles' },
    },
    {
      path: '/settings/notification-setting',
      name: 'SettingsNotificationSetting',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/settings/notifications/NotificationSetting.vue'),
      meta: { permission: 'Employee.Common.Notifications' },
    },
    {
      path: '/settings/paymentRequestTypes',
      name: 'SettingsPaymentRequestTypes',
      component: () =>
        import(
          /* webpackChunkName: "console-pages" */ '@/views/settings/paymentRequestTypes/PaymentRequestTypesList.vue'
        ),
    },
    {
      path: '/pricing/pricings',
      name: 'SettingsPricings',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/pricing/pricings/PricingsList.vue'),
      meta: { permission: 'Employee.Common.SettingsPricings' },
    },
    {
      path: '/settings/account-fields',
      name: 'AccountFields',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/pages/account-fields/AccountFields.vue'),
      meta: {},
    },
    // Support
    {
      path: '/tickets',
      name: 'Tickets',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/support/tickets/TicketList.vue'),
      meta: { permission: 'Employee.Support' },
    },
    {
      path: '/tickets/:id',
      name: 'Ticket',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/support/tickets/TicketConversation.vue'),
    },

    {
      path: '/myTickets',
      name: 'MyTickets',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/support/myTicket/TicketList.vue'),
    },
    {
      path: '/myTickets/:id',
      name: 'MyTicket',
      component: () =>
        import(/* webpackChunkName: "console-pages" */ '@/views/support/myTicket/TicketConversation.vue'),
    },
    {
      path: '/settings/cost-projects',
      name: 'CostProject',
      component: () => import(/* webpackChunkName: "console-pages" */ '@/views/settings/cost-projects/index.vue'),
      meta: {},
    },
    {
      path: '/chart',
      name: 'Chart',
      component: () => import(/* webpackChunkName: "ganttchart-page" */ '@/views/chart.vue'),
      meta: {},
    },
  ],
};
